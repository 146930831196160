import React, { useCallback } from 'react'
import { Transition } from '@headlessui/react'
import { useFormik } from 'formik'
import { useStaticQuery, graphql } from 'gatsby'
import { toast, ToastContainer } from 'react-toastify'
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import * as Yup from 'yup'
import Layout from '../components/Layout'
import Input from '../components/Input'
import Textarea from '../components/Textarea'
import Spinner from '../components/Spinner'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const FORM_ID = 'demo'
const IS_PROD = process.env.NODE_ENV === 'production'
const API_URL =
  process.env.API_URL || IS_PROD
    ? 'https://api.dfs.team'
    : 'https://qrxpw3bbw5.execute-api.us-east-1.amazonaws.com/dev'

const ContactPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { featuredImage } = useStaticQuery(graphql`
    {
      featuredImage: wordpressWpMedia(slug: { eq: "contact-media" }) {
        localFile {
          childImageSharp {
            fluid(quality: 100, maxHeight: 600) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  `)

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('This field is required')
      .max(100, 'First name is too long!'),
    lastName: Yup.string()
      .required('This field is required')
      .max(100, 'Last name is too long!'),
    email: Yup.string()
      .email('Email is invalid')
      .required('This field is required')
      .max(150, 'Email is too long!'),
    company: Yup.string()
      .max(100, 'Company is too long!')
      .required('This field is required'),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(10, 'Phone number is not valid')
      .max(10, 'Phone number is not valid'),
    country: Yup.string().max(100, 'Country is too long!'),
    productInfo: Yup.string().max(100, 'Product description is too long!'),
    otherInfo: Yup.string().max(100, 'Other info description is too long!'),
    recaptchaToken: Yup.string()
      .nullable(),
  })

  const onSubmit = async (
    values,
    { setSubmitting, setFieldError, setFieldValue, resetForm }
  ) => {
    setSubmitting(true)

    let reCaptchaResult = null
    if (executeRecaptcha) {
      reCaptchaResult = await executeRecaptcha()
    }

    if (reCaptchaResult) {
      setFieldValue('recaptchaToken', reCaptchaResult, true)
      const { recaptchaToken, ...formData } = values

      try {
        const result = await fetch(`${API_URL}/submit-form`, {
          method: 'POST',
          body: JSON.stringify({
            ...formData,
            formId: FORM_ID,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(response => response.json())

        if (result.success) {
          toast.success(
            'Success! Thanks for showing interest in us. We will get back to you shortly.',
            {
              position: toast.POSITION.TOP_CENTER,
              closeButton: (
                <button
                  type="button"
                  tabIndex="-1"
                  className="delete"
                  aria-label="delete"
                />
              ),
            }
          )
          resetForm()
        }
      } catch (err) {
        console.error('error: ', err)
        setSubmitting(false)
        toast.error('Something went wrong. Please try again later.', {
          position: toast.POSITION.TOP_CENTER,
          closeButton: (
            <button
              type="button"
              tabIndex="-1"
              className="delete"
              aria-label="delete"
            />
          ),
        })
      }
    } else {
      setFieldError('recaptchaToken', 'reCAPTCHA validation has failed.')
    }
    setSubmitting(false)
  }

  const {
    errors,
    touched,
    isSubmitting,
    isValidating,
    isValid,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleBlur,
    values,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      country: '',
      phoneNumber: '',
      productInfo: '',
      otherInfo: '',
      recaptchaToken: null,
    },
    onSubmit,
    validationSchema,
  })

  const handleReCaptchaVerify = useCallback(token => {
    setFieldValue('recaptchaToken', token)
  }, [])

  return (
    <Layout
      header={{
        title: 'Request a Demo',
        subtitle:
          'Do you want a product demo of a particular solution? Send us some information about you and we will get back to you.',
      }}
      seo={{
        pageTitle: 'Request A Demo',
        pageDescription:
          'Our staff would be happy to provide demos of software we provide. Fill out the demo form details to start scheduling today.',
      }}
      featuredMedia={featuredImage}
      lightText
    >
      <div role="main">
        <ToastContainer />
        <section className="section">
          <form
            onSubmit={handleSubmit}
            className="container relative space-y-6"
          >
            <div className="grid gap-y-4 lg:gap-6 lg:grid-cols-2">
              <Input
                name="firstName"
                label="First Name"
                value={values.firstName}
                error={errors.firstName}
                touched={touched.firstName}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
                required
              />
              <Input
                name="lastName"
                label="Last Name"
                value={values.lastName}
                error={errors.lastName}
                touched={touched.lastName}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
                required
              />
              <Input
                name="email"
                label="Email"
                value={values.email}
                error={errors.email}
                touched={touched.email}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="email"
                required
              />
              <Input
                name="company"
                label="Company"
                value={values.company}
                error={errors.company}
                touched={touched.company}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
                required
              />
              <Input
                name="phoneNumber"
                label="Phone Number"
                value={values.phoneNumber}
                error={errors.phoneNumber}
                touched={touched.phoneNumber}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="tel"
              />
              <Input
                name="country"
                label="Country"
                value={values.country}
                error={errors.country}
                touched={touched.country}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
              />
              <Textarea
                name="productInfo"
                label="Product Info"
                value={values.productInfo}
                error={errors.productInfo}
                touched={touched.productInfo}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
                required
              />
              <Textarea
                name="otherInfo"
                label="Other Info"
                value={values.otherInfo}
                error={errors.otherInfo}
                touched={touched.otherInfo}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
              />
            </div>
            <GoogleReCaptcha onVerify={handleReCaptchaVerify} />
            {errors.recaptchaToken && (
              <p className="text-red-700 text-sm">{errors.recaptchaToken}</p>
            )}
            <button
              type="submit"
              disabled={isSubmitting}
              className="relative w-auto flex text-background text-lg font-semibold leading-4 px-5 py-3 rounded-full text-left outline-none cursor-pointer bg-dfs text-white transition-all hover:bg-primary hover:text-white disabled:opacity-60 disabled:cursor-not-allowed"
            >
              <Transition
                show={!isValidating && isValid && isSubmitting}
                enter="transform transition-all duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transform transition-all duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Spinner className="w-4 h-4" />
              </Transition>
              Submit
            </button>
          </form>
        </section>
      </div>
    </Layout>
  )
}

export default () => (
  <GoogleReCaptchaProvider reCaptchaKey="6LcwGkMeAAAAAHnWGG7lCTmcMWvWZ82g4nSw2glh">
    <ContactPage />
  </GoogleReCaptchaProvider>
)
